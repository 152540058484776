define("lodash/internal/arrayExtremum", ["exports"], function (exports) {
  /**
   * A specialized version of `baseExtremum` for arrays which invokes `iteratee`
   * with one argument: (value).
   *
   * @private
   * @param {Array} array The array to iterate over.
   * @param {Function} iteratee The function invoked per iteration.
   * @param {Function} comparator The function used to compare values.
   * @param {*} exValue The initial extremum value.
   * @returns {*} Returns the extremum value.
   */
  "use strict";

  function arrayExtremum(array, iteratee, comparator, exValue) {
    var index = -1,
        length = array.length,
        computed = exValue,
        result = computed;

    while (++index < length) {
      var value = array[index],
          current = +iteratee(value);

      if (comparator(current, computed)) {
        computed = current;
        result = value;
      }
    }
    return result;
  }

  exports["default"] = arrayExtremum;
});