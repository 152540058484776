define('lodash/internal/baseDelay', ['exports'], function (exports) {
  /** Used as the `TypeError` message for "Functions" methods. */
  'use strict';

  var FUNC_ERROR_TEXT = 'Expected a function';

  /**
   * The base implementation of `_.delay` and `_.defer` which accepts an index
   * of where to slice the arguments to provide to `func`.
   *
   * @private
   * @param {Function} func The function to delay.
   * @param {number} wait The number of milliseconds to delay invocation.
   * @param {Object} args The arguments provide to `func`.
   * @returns {number} Returns the timer id.
   */
  function baseDelay(func, wait, args) {
    if (typeof func != 'function') {
      throw new TypeError(FUNC_ERROR_TEXT);
    }
    return setTimeout(function () {
      func.apply(undefined, args);
    }, wait);
  }

  exports['default'] = baseDelay;
});