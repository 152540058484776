define('lodash/internal/binaryIndex', ['exports', 'lodash/internal/binaryIndexBy', 'lodash/utility/identity'], function (exports, _lodashInternalBinaryIndexBy, _lodashUtilityIdentity) {
  'use strict';

  /** Used as references for the maximum length and index of an array. */
  var MAX_ARRAY_LENGTH = 4294967295,
      HALF_MAX_ARRAY_LENGTH = MAX_ARRAY_LENGTH >>> 1;

  /**
   * Performs a binary search of `array` to determine the index at which `value`
   * should be inserted into `array` in order to maintain its sort order.
   *
   * @private
   * @param {Array} array The sorted array to inspect.
   * @param {*} value The value to evaluate.
   * @param {boolean} [retHighest] Specify returning the highest qualified index.
   * @returns {number} Returns the index at which `value` should be inserted
   *  into `array`.
   */
  function binaryIndex(array, value, retHighest) {
    var low = 0,
        high = array ? array.length : low;

    if (typeof value == 'number' && value === value && high <= HALF_MAX_ARRAY_LENGTH) {
      while (low < high) {
        var mid = low + high >>> 1,
            computed = array[mid];

        if ((retHighest ? computed <= value : computed < value) && computed !== null) {
          low = mid + 1;
        } else {
          high = mid;
        }
      }
      return high;
    }
    return (0, _lodashInternalBinaryIndexBy['default'])(array, value, _lodashUtilityIdentity['default'], retHighest);
  }

  exports['default'] = binaryIndex;
});