define("lodash/internal/arrayReduceRight", ["exports"], function (exports) {
  /**
   * A specialized version of `_.reduceRight` for arrays without support for
   * callback shorthands and `this` binding.
   *
   * @private
   * @param {Array} array The array to iterate over.
   * @param {Function} iteratee The function invoked per iteration.
   * @param {*} [accumulator] The initial value.
   * @param {boolean} [initFromArray] Specify using the last element of `array`
   *  as the initial value.
   * @returns {*} Returns the accumulated value.
   */
  "use strict";

  function arrayReduceRight(array, iteratee, accumulator, initFromArray) {
    var length = array.length;
    if (initFromArray && length) {
      accumulator = array[--length];
    }
    while (length--) {
      accumulator = iteratee(accumulator, array[length], length, array);
    }
    return accumulator;
  }

  exports["default"] = arrayReduceRight;
});