define("lodash/internal/arrayEachRight", ["exports"], function (exports) {
  /**
   * A specialized version of `_.forEachRight` for arrays without support for
   * callback shorthands and `this` binding.
   *
   * @private
   * @param {Array} array The array to iterate over.
   * @param {Function} iteratee The function invoked per iteration.
   * @returns {Array} Returns `array`.
   */
  "use strict";

  function arrayEachRight(array, iteratee) {
    var length = array.length;

    while (length--) {
      if (iteratee(array[length], length, array) === false) {
        break;
      }
    }
    return array;
  }

  exports["default"] = arrayEachRight;
});