define('lodash/collection/sortBy', ['exports', 'lodash/internal/baseCallback', 'lodash/internal/baseMap', 'lodash/internal/baseSortBy', 'lodash/internal/compareAscending', 'lodash/internal/isIterateeCall'], function (exports, _lodashInternalBaseCallback, _lodashInternalBaseMap, _lodashInternalBaseSortBy, _lodashInternalCompareAscending, _lodashInternalIsIterateeCall) {
  'use strict';

  /**
   * Creates an array of elements, sorted in ascending order by the results of
   * running each element in a collection through `iteratee`. This method performs
   * a stable sort, that is, it preserves the original sort order of equal elements.
   * The `iteratee` is bound to `thisArg` and invoked with three arguments:
   * (value, index|key, collection).
   *
   * If a property name is provided for `iteratee` the created `_.property`
   * style callback returns the property value of the given element.
   *
   * If a value is also provided for `thisArg` the created `_.matchesProperty`
   * style callback returns `true` for elements that have a matching property
   * value, else `false`.
   *
   * If an object is provided for `iteratee` the created `_.matches` style
   * callback returns `true` for elements that have the properties of the given
   * object, else `false`.
   *
   * @static
   * @memberOf _
   * @category Collection
   * @param {Array|Object|string} collection The collection to iterate over.
   * @param {Function|Object|string} [iteratee=_.identity] The function invoked
   *  per iteration.
   * @param {*} [thisArg] The `this` binding of `iteratee`.
   * @returns {Array} Returns the new sorted array.
   * @example
   *
   * _.sortBy([1, 2, 3], function(n) {
   *   return Math.sin(n);
   * });
   * // => [3, 1, 2]
   *
   * _.sortBy([1, 2, 3], function(n) {
   *   return this.sin(n);
   * }, Math);
   * // => [3, 1, 2]
   *
   * var users = [
   *   { 'user': 'fred' },
   *   { 'user': 'pebbles' },
   *   { 'user': 'barney' }
   * ];
   *
   * // using the `_.property` callback shorthand
   * _.pluck(_.sortBy(users, 'user'), 'user');
   * // => ['barney', 'fred', 'pebbles']
   */
  function sortBy(collection, iteratee, thisArg) {
    if (collection == null) {
      return [];
    }
    if (thisArg && (0, _lodashInternalIsIterateeCall['default'])(collection, iteratee, thisArg)) {
      iteratee = undefined;
    }
    var index = -1;
    iteratee = (0, _lodashInternalBaseCallback['default'])(iteratee, thisArg, 3);

    var result = (0, _lodashInternalBaseMap['default'])(collection, function (value, key, collection) {
      return { 'criteria': iteratee(value, key, collection), 'index': ++index, 'value': value };
    });
    return (0, _lodashInternalBaseSortBy['default'])(result, _lodashInternalCompareAscending['default']);
  }

  exports['default'] = sortBy;
});