define('lodash/internal/mergeData', ['exports', 'lodash/internal/arrayCopy', 'lodash/internal/composeArgs', 'lodash/internal/composeArgsRight', 'lodash/internal/replaceHolders'], function (exports, _lodashInternalArrayCopy, _lodashInternalComposeArgs, _lodashInternalComposeArgsRight, _lodashInternalReplaceHolders) {
  'use strict';

  /** Used to compose bitmasks for wrapper metadata. */
  var BIND_FLAG = 1,
      CURRY_BOUND_FLAG = 4,
      CURRY_FLAG = 8,
      ARY_FLAG = 128,
      REARG_FLAG = 256;

  /** Used as the internal argument placeholder. */
  var PLACEHOLDER = '__lodash_placeholder__';

  /* Native method references for those with the same name as other `lodash` methods. */
  var nativeMin = Math.min;

  /**
   * Merges the function metadata of `source` into `data`.
   *
   * Merging metadata reduces the number of wrappers required to invoke a function.
   * This is possible because methods like `_.bind`, `_.curry`, and `_.partial`
   * may be applied regardless of execution order. Methods like `_.ary` and `_.rearg`
   * augment function arguments, making the order in which they are executed important,
   * preventing the merging of metadata. However, we make an exception for a safe
   * common case where curried functions have `_.ary` and or `_.rearg` applied.
   *
   * @private
   * @param {Array} data The destination metadata.
   * @param {Array} source The source metadata.
   * @returns {Array} Returns `data`.
   */
  function mergeData(data, source) {
    var bitmask = data[1],
        srcBitmask = source[1],
        newBitmask = bitmask | srcBitmask,
        isCommon = newBitmask < ARY_FLAG;

    var isCombo = srcBitmask == ARY_FLAG && bitmask == CURRY_FLAG || srcBitmask == ARY_FLAG && bitmask == REARG_FLAG && data[7].length <= source[8] || srcBitmask == (ARY_FLAG | REARG_FLAG) && bitmask == CURRY_FLAG;

    // Exit early if metadata can't be merged.
    if (!(isCommon || isCombo)) {
      return data;
    }
    // Use source `thisArg` if available.
    if (srcBitmask & BIND_FLAG) {
      data[2] = source[2];
      // Set when currying a bound function.
      newBitmask |= bitmask & BIND_FLAG ? 0 : CURRY_BOUND_FLAG;
    }
    // Compose partial arguments.
    var value = source[3];
    if (value) {
      var partials = data[3];
      data[3] = partials ? (0, _lodashInternalComposeArgs['default'])(partials, value, source[4]) : (0, _lodashInternalArrayCopy['default'])(value);
      data[4] = partials ? (0, _lodashInternalReplaceHolders['default'])(data[3], PLACEHOLDER) : (0, _lodashInternalArrayCopy['default'])(source[4]);
    }
    // Compose partial right arguments.
    value = source[5];
    if (value) {
      partials = data[5];
      data[5] = partials ? (0, _lodashInternalComposeArgsRight['default'])(partials, value, source[6]) : (0, _lodashInternalArrayCopy['default'])(value);
      data[6] = partials ? (0, _lodashInternalReplaceHolders['default'])(data[5], PLACEHOLDER) : (0, _lodashInternalArrayCopy['default'])(source[6]);
    }
    // Use source `argPos` if available.
    value = source[7];
    if (value) {
      data[7] = (0, _lodashInternalArrayCopy['default'])(value);
    }
    // Use source `ary` if it's smaller.
    if (srcBitmask & ARY_FLAG) {
      data[8] = data[8] == null ? source[8] : nativeMin(data[8], source[8]);
    }
    // Use source `arity` if one is not provided.
    if (data[9] == null) {
      data[9] = source[9];
    }
    // Use source `func` and merge bitmasks.
    data[0] = source[0];
    data[1] = newBitmask;

    return data;
  }

  exports['default'] = mergeData;
});