define('lodash/utility/matchesProperty', ['exports', 'lodash/internal/baseClone', 'lodash/internal/baseMatchesProperty'], function (exports, _lodashInternalBaseClone, _lodashInternalBaseMatchesProperty) {
  'use strict';

  /**
   * Creates a function that compares the property value of `path` on a given
   * object to `value`.
   *
   * **Note:** This method supports comparing arrays, booleans, `Date` objects,
   * numbers, `Object` objects, regexes, and strings. Objects are compared by
   * their own, not inherited, enumerable properties.
   *
   * @static
   * @memberOf _
   * @category Utility
   * @param {Array|string} path The path of the property to get.
   * @param {*} srcValue The value to match.
   * @returns {Function} Returns the new function.
   * @example
   *
   * var users = [
   *   { 'user': 'barney' },
   *   { 'user': 'fred' }
   * ];
   *
   * _.find(users, _.matchesProperty('user', 'fred'));
   * // => { 'user': 'fred' }
   */
  function matchesProperty(path, srcValue) {
    return (0, _lodashInternalBaseMatchesProperty['default'])(path, (0, _lodashInternalBaseClone['default'])(srcValue, true));
  }

  exports['default'] = matchesProperty;
});