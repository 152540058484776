define('lodash/lang/isError', ['exports', 'lodash/internal/isObjectLike'], function (exports, _lodashInternalIsObjectLike) {
  'use strict';

  /** `Object#toString` result references. */
  var errorTag = '[object Error]';

  /** Used for native method references. */
  var objectProto = Object.prototype;

  /**
   * Used to resolve the [`toStringTag`](http://ecma-international.org/ecma-262/6.0/#sec-object.prototype.tostring)
   * of values.
   */
  var objToString = objectProto.toString;

  /**
   * Checks if `value` is an `Error`, `EvalError`, `RangeError`, `ReferenceError`,
   * `SyntaxError`, `TypeError`, or `URIError` object.
   *
   * @static
   * @memberOf _
   * @category Lang
   * @param {*} value The value to check.
   * @returns {boolean} Returns `true` if `value` is an error object, else `false`.
   * @example
   *
   * _.isError(new Error);
   * // => true
   *
   * _.isError(Error);
   * // => false
   */
  function isError(value) {
    return (0, _lodashInternalIsObjectLike['default'])(value) && typeof value.message == 'string' && objToString.call(value) == errorTag;
  }

  exports['default'] = isError;
});