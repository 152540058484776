define('lodash/internal/baseIndexOf', ['exports', 'lodash/internal/indexOfNaN'], function (exports, _lodashInternalIndexOfNaN) {
  'use strict';

  /**
   * The base implementation of `_.indexOf` without support for binary searches.
   *
   * @private
   * @param {Array} array The array to search.
   * @param {*} value The value to search for.
   * @param {number} fromIndex The index to search from.
   * @returns {number} Returns the index of the matched value, else `-1`.
   */
  function baseIndexOf(array, value, fromIndex) {
    if (value !== value) {
      return (0, _lodashInternalIndexOfNaN['default'])(array, fromIndex);
    }
    var index = fromIndex - 1,
        length = array.length;

    while (++index < length) {
      if (array[index] === value) {
        return index;
      }
    }
    return -1;
  }

  exports['default'] = baseIndexOf;
});