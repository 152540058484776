define("lodash/internal/baseLodash", ["exports"], function (exports) {
  /**
   * The function whose prototype all chaining wrappers inherit from.
   *
   * @private
   */
  "use strict";

  function baseLodash() {
    // No operation performed.
  }

  exports["default"] = baseLodash;
});