define('lodash/function', ['exports', 'lodash/function/after', 'lodash/function/ary', 'lodash/function/backflow', 'lodash/function/before', 'lodash/function/bind', 'lodash/function/bindAll', 'lodash/function/bindKey', 'lodash/function/compose', 'lodash/function/curry', 'lodash/function/curryRight', 'lodash/function/debounce', 'lodash/function/defer', 'lodash/function/delay', 'lodash/function/flow', 'lodash/function/flowRight', 'lodash/function/memoize', 'lodash/function/modArgs', 'lodash/function/negate', 'lodash/function/once', 'lodash/function/partial', 'lodash/function/partialRight', 'lodash/function/rearg', 'lodash/function/restParam', 'lodash/function/spread', 'lodash/function/throttle', 'lodash/function/wrap'], function (exports, _lodashFunctionAfter, _lodashFunctionAry, _lodashFunctionBackflow, _lodashFunctionBefore, _lodashFunctionBind, _lodashFunctionBindAll, _lodashFunctionBindKey, _lodashFunctionCompose, _lodashFunctionCurry, _lodashFunctionCurryRight, _lodashFunctionDebounce, _lodashFunctionDefer, _lodashFunctionDelay, _lodashFunctionFlow, _lodashFunctionFlowRight, _lodashFunctionMemoize, _lodashFunctionModArgs, _lodashFunctionNegate, _lodashFunctionOnce, _lodashFunctionPartial, _lodashFunctionPartialRight, _lodashFunctionRearg, _lodashFunctionRestParam, _lodashFunctionSpread, _lodashFunctionThrottle, _lodashFunctionWrap) {
  'use strict';

  exports['default'] = {
    'after': _lodashFunctionAfter['default'],
    'ary': _lodashFunctionAry['default'],
    'backflow': _lodashFunctionBackflow['default'],
    'before': _lodashFunctionBefore['default'],
    'bind': _lodashFunctionBind['default'],
    'bindAll': _lodashFunctionBindAll['default'],
    'bindKey': _lodashFunctionBindKey['default'],
    'compose': _lodashFunctionCompose['default'],
    'curry': _lodashFunctionCurry['default'],
    'curryRight': _lodashFunctionCurryRight['default'],
    'debounce': _lodashFunctionDebounce['default'],
    'defer': _lodashFunctionDefer['default'],
    'delay': _lodashFunctionDelay['default'],
    'flow': _lodashFunctionFlow['default'],
    'flowRight': _lodashFunctionFlowRight['default'],
    'memoize': _lodashFunctionMemoize['default'],
    'modArgs': _lodashFunctionModArgs['default'],
    'negate': _lodashFunctionNegate['default'],
    'once': _lodashFunctionOnce['default'],
    'partial': _lodashFunctionPartial['default'],
    'partialRight': _lodashFunctionPartialRight['default'],
    'rearg': _lodashFunctionRearg['default'],
    'restParam': _lodashFunctionRestParam['default'],
    'spread': _lodashFunctionSpread['default'],
    'throttle': _lodashFunctionThrottle['default'],
    'wrap': _lodashFunctionWrap['default']
  };
});