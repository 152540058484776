define('lodash/object/get', ['exports', 'lodash/internal/baseGet', 'lodash/internal/toPath'], function (exports, _lodashInternalBaseGet, _lodashInternalToPath) {
  'use strict';

  /**
   * Gets the property value at `path` of `object`. If the resolved value is
   * `undefined` the `defaultValue` is used in its place.
   *
   * @static
   * @memberOf _
   * @category Object
   * @param {Object} object The object to query.
   * @param {Array|string} path The path of the property to get.
   * @param {*} [defaultValue] The value returned if the resolved value is `undefined`.
   * @returns {*} Returns the resolved value.
   * @example
   *
   * var object = { 'a': [{ 'b': { 'c': 3 } }] };
   *
   * _.get(object, 'a[0].b.c');
   * // => 3
   *
   * _.get(object, ['a', '0', 'b', 'c']);
   * // => 3
   *
   * _.get(object, 'a.b.c', 'default');
   * // => 'default'
   */
  function get(object, path, defaultValue) {
    var result = object == null ? undefined : (0, _lodashInternalBaseGet['default'])(object, (0, _lodashInternalToPath['default'])(path), path + '');
    return result === undefined ? defaultValue : result;
  }

  exports['default'] = get;
});