define("lodash/internal/mapDelete", ["exports"], function (exports) {
  /**
   * Removes `key` and its value from the cache.
   *
   * @private
   * @name delete
   * @memberOf _.memoize.Cache
   * @param {string} key The key of the value to remove.
   * @returns {boolean} Returns `true` if the entry was removed successfully, else `false`.
   */
  "use strict";

  function mapDelete(key) {
    return this.has(key) && delete this.__data__[key];
  }

  exports["default"] = mapDelete;
});