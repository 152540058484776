define('lodash/internal/bindCallback', ['exports', 'lodash/utility/identity'], function (exports, _lodashUtilityIdentity) {
  'use strict';

  /**
   * A specialized version of `baseCallback` which only supports `this` binding
   * and specifying the number of arguments to provide to `func`.
   *
   * @private
   * @param {Function} func The function to bind.
   * @param {*} thisArg The `this` binding of `func`.
   * @param {number} [argCount] The number of arguments to provide to `func`.
   * @returns {Function} Returns the callback.
   */
  function bindCallback(func, thisArg, argCount) {
    if (typeof func != 'function') {
      return _lodashUtilityIdentity['default'];
    }
    if (thisArg === undefined) {
      return func;
    }
    switch (argCount) {
      case 1:
        return function (value) {
          return func.call(thisArg, value);
        };
      case 3:
        return function (value, index, collection) {
          return func.call(thisArg, value, index, collection);
        };
      case 4:
        return function (accumulator, value, index, collection) {
          return func.call(thisArg, accumulator, value, index, collection);
        };
      case 5:
        return function (value, other, key, object, source) {
          return func.call(thisArg, value, other, key, object, source);
        };
    }
    return function () {
      return func.apply(thisArg, arguments);
    };
  }

  exports['default'] = bindCallback;
});