define('lodash/array/pull', ['exports', 'lodash/internal/baseIndexOf'], function (exports, _lodashInternalBaseIndexOf) {
  'use strict';

  /** Used for native method references. */
  var arrayProto = Array.prototype;

  /** Native method references. */
  var splice = arrayProto.splice;

  /**
   * Removes all provided values from `array` using
   * [`SameValueZero`](http://ecma-international.org/ecma-262/6.0/#sec-samevaluezero)
   * for equality comparisons.
   *
   * **Note:** Unlike `_.without`, this method mutates `array`.
   *
   * @static
   * @memberOf _
   * @category Array
   * @param {Array} array The array to modify.
   * @param {...*} [values] The values to remove.
   * @returns {Array} Returns `array`.
   * @example
   *
   * var array = [1, 2, 3, 1, 2, 3];
   *
   * _.pull(array, 2, 3);
   * console.log(array);
   * // => [1, 1]
   */
  function pull() {
    var args = arguments,
        array = args[0];

    if (!(array && array.length)) {
      return array;
    }
    var index = 0,
        indexOf = _lodashInternalBaseIndexOf['default'],
        length = args.length;

    while (++index < length) {
      var fromIndex = 0,
          value = args[index];

      while ((fromIndex = indexOf(array, value, fromIndex)) > -1) {
        splice.call(array, fromIndex, 1);
      }
    }
    return array;
  }

  exports['default'] = pull;
});