define('lodash/internal/pickByArray', ['exports', 'lodash/internal/toObject'], function (exports, _lodashInternalToObject) {
  'use strict';

  /**
   * A specialized version of `_.pick` which picks `object` properties specified
   * by `props`.
   *
   * @private
   * @param {Object} object The source object.
   * @param {string[]} props The property names to pick.
   * @returns {Object} Returns the new object.
   */
  function pickByArray(object, props) {
    object = (0, _lodashInternalToObject['default'])(object);

    var index = -1,
        length = props.length,
        result = {};

    while (++index < length) {
      var key = props[index];
      if (key in object) {
        result[key] = object[key];
      }
    }
    return result;
  }

  exports['default'] = pickByArray;
});